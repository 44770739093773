import { ref, watch } from 'vue';
import { dateFormat } from '@/common';
import dayjs from "dayjs";

import { nextTick } from 'vue';
export default {
  props: {
    announcementInfo: {
      type: Object,
      default: () => {}
    }
  },
  setup(props) {
    const announcement = ref();
    const content = ref();
    // const previewImage = (element) => {
    //     element.removeEventListener('click', previewFn);
    //     element.addEventListener('click', previewFn, false);
    // }
    const getImgDom = () => {
      nextTick(() => {
        const imgArr = content.value.querySelectorAll('img');
        if (!(imgArr !== null && imgArr !== void 0 && imgArr.length)) {
          return;
        }
        imgArr.forEach(item => {
          item.removeEventListener('click', () => previewFn(item));
          item.addEventListener('click', () => previewFn(item), false);
        });
      });
    };
    const previewFn = e => {
      // 检查是否点击的是图片
      if (e.nodeName === 'IMG') {
        let imageSrc = e.src;
        // 创建一个大图预览的模态框
        let modal = document.createElement('div');
        modal.classList.add('helpCenterImgModal');
        let modalImg = document.createElement('img');
        modalImg.src = imageSrc;
        modal.appendChild(modalImg);
        // 添加模态框到页面
        document.body.appendChild(modal);
        // 点击模态框关闭预览
        modal.onclick = function () {
          document.body.removeChild(modal);
        };
        // modal.addEventListener('wheel', function () {
        //     document.body.removeChild(modal);
        // });
        // window.addEventListener('keyup', function (event) {
        //   console.log(event, 'event')
        //   if (event.key === 'Escape') {
        //     // 按下了 Esc 键
        //     // document.body.removeChild(modal);
        //   }
        // });
      }
    };
    watch(() => props.announcementInfo, newValue => {
      announcement.value = newValue;
      announcement.value.sendTime = dayjs(newValue.sendTime).format(dateFormat);
      getImgDom();
    }, {
      deep: true,
      immediate: true
    });
    return {
      announcement,
      content
    };
  }
};