/*
 * @Author: fangheng fangheng@wedobest.com.cn
 * @Date: 2023-10-19 20:21:54
 * @LastEditors: fangheng fangheng@wedobest.com.cn
 * @LastEditTime: 2023-10-21 10:28:16
 * @FilePath: \bestbuildweb\src\hooks\useHorn.js
 * @Description: 这是默认设置,请设置`customMade`, 打开koroFileHeader查看配置 进行设置: https://github.com/OBKoro1/koro1FileHeader/wiki/%E9%85%8D%E7%BD%AE
 */
import { OK } from '@/common';
import { useStore } from '@/store'
import {
    postListUserNotice,
  } from '@/api/systemNotification'
export function useHorn() {
    const store = useStore()
    const info:any = sessionStorage.getItem('userInfo')
    const userInfo = JSON.parse(info)
    
    const getUserNotice =async (myID?:string)=>{
            let userId = myID || userInfo?.id
            if(!userId) return
            let params = {
                userId
            }
            try {
                let res = await postListUserNotice(params)
                if (res.code === OK) {
                    store.setNoticeInfo(res)
                }
            } catch (e) {
                console.info('🚀file:ProductBuildHistory line:151', e)
            }
        };
    return {
        getUserNotice
    }
}