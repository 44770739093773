import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue";
const _withScopeId = n => (_pushScopeId("data-v-933ea228"), n = n(), _popScopeId(), n);
const _hoisted_1 = {
  class: "modal-wrap"
};
const _hoisted_2 = {
  class: "title"
};
const _hoisted_3 = ["innerHTML"];
const _hoisted_4 = {
  class: "footer"
};
const _hoisted_5 = {
  class: "user"
};
const _hoisted_6 = {
  class: "time"
};
export function render(_ctx, _cache, $props, $setup, $data, $options) {
  var _$setup$announcement, _$setup$announcement2, _$setup$announcement3;
  return _openBlock(), _createElementBlock("div", _hoisted_1, [_createElementVNode("div", _hoisted_2, _toDisplayString((_$setup$announcement = $setup.announcement) === null || _$setup$announcement === void 0 ? void 0 : _$setup$announcement.title), 1), _createElementVNode("div", {
    innerHTML: (_$setup$announcement2 = $setup.announcement) === null || _$setup$announcement2 === void 0 ? void 0 : _$setup$announcement2.content,
    class: "minheight",
    ref: "content"
  }, null, 8, _hoisted_3), _createElementVNode("div", _hoisted_4, [_createElementVNode("span", _hoisted_5, _toDisplayString((_$setup$announcement3 = $setup.announcement) === null || _$setup$announcement3 === void 0 ? void 0 : _$setup$announcement3.createUser), 1), _createElementVNode("span", _hoisted_6, "   " + _toDisplayString($setup.announcement.sendTime), 1)])]);
}